<template>
	<section>
		<div class="banner">
			<div class="section">
				<div class="section-content banner-con">
					<div class="top-title">营销技术提供商</div>
					<div class="top-text">
						<p>为客户提供销售增长的数字化营销解决方案</p>
						<p>致力于企业的销售增长</p>
					</div>

				</div>
			</div>
		</div>
		<div class="section">
			<div class="section-content">
				<!-- 产品服务 -->
				<div class="part1">
					<div class="part">
						<div class="title-module">
							<div class="main-title">公司简介</div>
							<div class="sub-title">
								<p>为企业搭建一站式营销中心，可轻松</p>
								<p>对接多个营销终端。</p>
							</div>
						</div>
						<div class="company-con">
							<p>聚码是深圳聚码科技有限公司（简称聚码）旗下的移动互联网营销平台，聚码是一家助力中小企业数字化经营升级的企业，创立于2019年9月。公司坚持以“让中小企业经营更简单”的企业理念，为中小企业用户提供优质的互联网产品与服务。</p>
							<p>目前，聚码旗下拥有聚码和手机回收两大业务板块，覆盖全场景营销门户、智慧电商零售、数字化门店、自助式营销、人工智能设计、智能销售推广等多种中小企业经营场景，帮助用户借助互联网技术的力量，更高效地进行经营。</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		
	</section>
</template>
<script type="text/javascript">
	export default {
    data(){
        return {
        	url:''
        }
    },
	mounted(){
		console.log('this is about')
	},
	methods: {

	},
  
  // router
}

</script>
<style type="text/css" scoped>
	.banner{
		width: 100%;
		height: 300px;
		background-image: url(https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/b66663.png);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		background-color: #fff;
		overflow: hidden;
		color: #333;
	}
	.banner-con{
		padding-left: 120px;
	}
    .top-title{
		font-size: 24px;
		line-height: 24px;
		font-weight: bolder;
		margin-top: 80px;
	}
	.top-text{
		font-size: 15px;
		font-weight: 300;
		padding-top: 6px;
		color: #333;
	}
	.part{
    	width: 100%;
    	background-color: #fff;
    	border-radius: 30px;
    	overflow: hidden;
    	margin-top: 60px;

    }

    .part1{
    	width: 100%;
    	padding: 0 40px 64px 40px;

    }
    .company-con{
    	font-size: 14px;
    	line-height: 26px;
    	color: #333;
    	padding: 60px 180px 80px 180px;
    }
	.company-con p{
		text-indent: 32px;
		margin-top: 12px;
	}
</style>